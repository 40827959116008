import Vue from 'vue'
import Router from 'vue-router'
import { loadLang } from 'src/i18n'
import config from 'src/config'
import currentLang from 'scripts/lang'

const Register = () => import('pages/Register')
const Login = () => import('pages/Login')
const Landing = () => import('pages/landing/Landing')
const Restore = () => import('pages/Restore')
const Gallery = () => import('pages/Gallery')
const GalleryViewer = () => import('pages/GalleryViewer')
const Banner = () => import('pages/Banner')
const Leader = () => import('pages/Leader')
const SurveyRegister = () => import('pages/SurveyRegister')
const SurveyResult = () => import('pages/SurveyResult')
const Terms = () => import('pages/Terms')

//Usuario
const Home = () => import('pages/Home')
const Stats = () => import('pages/Stats')
const Share = () => import('pages/Share')
const Account = () => import('pages/Account')
const Friend = () => import('pages/Friend')
const Chat = () => import('pages/Chat')
const Routes = () => import('pages/routes/Routes')
const CustomNetwork = () => import('pages/network/CustomNetwork')
const Stand = () => import('pages/stand/Stand')

//Admin
const Dashboard = () => import('pages/Dashboard')
const Ranking = () => import('pages/Ranking')
const Plan = () => import('pages/Plan')
const Networks = () => import('pages/Networks')
const Settings = () => import('pages/Settings')
const Region = () => import('pages/Region')
const SpecialRegion = () => import('pages/SpecialRegion')
const Transaction = () => import('pages/Transaction')
const Subadmin = () => import('pages/Subadmin')
const User = () => import('pages/User')
const RankingL = () => import('pages/RankingL')
const PendingNote = () => import('pages/PendingNote')
const Translate = () => import('pages/Translate')
const Survey = () => import('pages/Survey')
const CustomNetworkUsers = () => import('pages/network/CustomNetworkUsers')
const SyncContact  = () => import('pages/contacts/SyncContact')
const OAuthContact = () => import('pages/contacts/OAuth')
const WhatsApp = () => import('pages/whatsapp/WhatsApp')
const StandAdmin = () => import('pages/stand/StandAdmin')
const StandAdminRegister = () => import('pages/stand/StandAdminRegister')

//Callcenter
const Callcenter = () => import('pages/callcenter/Callcenter')
const Results = () => import('pages/callcenter/Results')

Vue.use(Router)

export function createRouter () {

	let router = new Router({
		mode: 'history',
		routes: [
			{
				path: '/register',
				name: 'register',
				component: Register,
				meta: {
					auth: false
				}
			},
			{
				path: '/e/:survey',
				name: 'surveyRegister',
				component: SurveyRegister,
				meta: {
					auth: true,
					authOptional: true
				}
			},
			{
				path: '/login',
				name: 'login',
				component: Login,
				meta: {
					auth: false
				}
			},
			{
				path: '/',
				name: 'landing',
				components: {
					default: Landing,
					proxiedSurvey: SurveyRegister
				},
				meta: {
					auth: false,
					authOptional: true
				}
			},
			{
				path: '/restore',
				component: Restore,
				meta: {
					auth: false
				}
			},
			{
				path: '/gallery/:id',
				component: GalleryViewer,
				meta: {
					auth: false
				}
			},

			// Usuario
			{
				path: '/home',
				component: Home,
				meta: {
					auth: true
				}
			},
			{
				path: '/stats',
				component: Stats,
				meta: {
					auth: true
				}
			},
			{
				path: '/share',
				component: Share,
				meta: {
					auth: true
				}
			},
			{
				path: '/ranking-s',
				component: Ranking,
				props: {
					isadmin : false,
					isemail: true,
					iscomment: true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/ranking-l',
				component: RankingL,
				props: {
					isadmin: false
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/pending-note-s',
				component: PendingNote,
				props: {
					isadmin: false,
					isemail : true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/gallery-s',
				component: Gallery,
				props: {
					isadmin: false
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/banner-s',
				component: Banner,
				props: {
					isadmin: false
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/leader-s',
				component: Leader,
				props: {
					isadmin: false
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/account',
				component: Account,
				meta: {
					auth: true
				}
			},
			{
				path: '/friend',
				component: Friend,
				meta: {
					auth: true
				}
			},
			{
				path: '/chat',
				component: Chat,
				meta: {
					auth: true
				}
			},
			{
				path: '/routes',
				component: Routes,
				meta: {
					auth: true
				}
			},
			{
				path: '/custom-network',
				name: 'custom-network',
				component: CustomNetwork,
				meta: {
					auth: true
				}
			},
			{
				path: '/register-stand',
				name: 'register-stand',
				component: Stand,
				meta: {
					auth: true
				}
			},

			// Admin
			{
				path: '/dashboard',
				component: Dashboard,
				props: {
					isadmin: true,
					issubadmin: true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/ranking',
				component: Ranking,
				props: {
					isadmin: true,
					issubadmin: true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/plan',
				component: Plan,
				props: {
					isadmin: true,
					issubadmin: true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/networks',
				component: Networks,
				props: {
					isadmin: true,
					issubadmin: true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/settings',
				component: Settings,
				props: {
					isadmin: true,
					issubadmin: false
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/translate',
				component: Translate,
				props: {
					isadmin: true,
					issubadmin: false,
					istranslator: true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/survey',
				component: Survey,
				props: {
					isadmin       : true,
					issubadmin    : true,
					isemail       : true,
					isemailsurvey : true  
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/survey/:survey',
				name: 'surveyResult',
				component: SurveyResult,
				props: {
					isadmin: true,
					issubadmin: true,
					isemail: true
				},
				meta: {
					auth: true,
				}
			},
			{
				path: '/whatsapp',
				component: WhatsApp,
				props: {
					isadmin: true,
					issubadmin: true,
					isemail	  : true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/custom-network-users',
				component: CustomNetworkUsers,
				props: {
					isadmin: true,
					issubadmin: true,
					isemail	  : true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/gallery',
				component: Gallery,
				props: {
					isadmin: true,
					issubadmin: true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/banner',
				component: Banner,
				props: {
					isadmin: true,
					issubadmin: true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/leader',
				component: Leader,
				props: {
					isadmin: true,
					issubadmin: true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/region',
				component: Region,
				props: {
					isadmin: true,
					issubadmin: false
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/region-s',
				component: SpecialRegion,
				props: {
					isadmin: true,
					issubadmin: false
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/transaction',
				component: Transaction,
				props: {
					isadmin: true,
					issubadmin: false
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/subadmin',
				component: Subadmin,
				props: {
					isadmin: true,
					issubadmin: false
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/user',
				component: User,
				props: {
					isadmin: true,
					issubadmin: false
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/s-account',
				props: {
					isadmin: true,
					issubadmin: true
				},
				component: Account,
				meta: {
					auth: true
				}
			},
			{
				path: '/pending-note',
				component: PendingNote,
				props: {
					isadmin: true,
					issubadmin: true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/stand',
				name: 'stand',
				component: StandAdmin,
				props: {
					isadmin: true,
					issubadmin: true,
					isemail: true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/register-stand-admin',
				name: 'register-stand-admin',
				component: StandAdminRegister,
				props: {
					isadmin: true,
					issubadmin: true
				},
				meta: {
					auth: true
				}
			},

			//Callcenter
			{
				path: '/callcenter',
				component: Callcenter,
				props: {
					isadmin: false,
					issubadmin  : false,
					iscallcenter: true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/callcenter-result',
				component: Results,
				props: {
					isadmin: false,
					issubadmin  : false,
					iscallcenter: true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/c-account',
				props: {
					isadmin: false,
					issubadmin: false,
					iscallcenter: true,
					istranslator: true
				},
				component: Account,
				meta: {
					auth: true
				}
			},
			
			// Términos y condiciones
			{
				path: '/terms',
				name: 'terms',
				component: Terms,
				meta: {
					auth: false,
				}
			},

			// Sincronización de contactos
			{
				path: '/sync-contact',
				component: SyncContact,
				props: {
					isadmin: true,
					issubadmin: true,
					isemail: true
				},
				meta: {
					auth: true
				}
			},
			{
				path: '/oauth/:server',
				component: OAuthContact,
				meta: {
					auth: false,
					noredirect: true
				}
			},
		]
	})

	router.beforeEach((to, from, next) => {

		let lang = currentLang(config.languages, config.fallbackLanguage)
		loadLang(lang).then(() => { next() })
	})

	return router
}