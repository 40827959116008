<template>
    <div id="app">
        <v-app>
            <router-view class="view" v-if="!proxiedSurvey" :key="$route.fullPath"></router-view>
            <router-view class="view" v-else name="proxiedSurvey" :key="$route.fullPath"></router-view>
            <vue-progress-bar></vue-progress-bar>
            <v-footer v-if="!loadingPage && (Object.keys(currentNetwork).length ? !currentNetwork.custom_domain : true)" class="footer-app" :data-name="$route.name" absolute>
                <v-spacer></v-spacer>
                <small class="full-width text-xs-center">{{ $t('Página construida sobre la plataforma de') }} <a href="https://wappid.com" target="_blank">wappid.com</a></small>
            </v-footer>
        </v-app>
	</div>
</template>

<style lang="stylus" src="public/stylus/main.styl"></style>

<script>

    export default {
        data() {
            return {
                currentNetwork: {},
                proxiedSurvey: false,
                loadingPage: true
            }
        },
        beforeMount(){
            this.proxiedSurvey = window.NETWORK ? window.NETWORK.proxiedSurvey : false
        },
        mounted() {
            this.currentNetwork = window.NETWORK ? window.NETWORK : {}
            this.loadingPage = false
        }
    }
</script>
